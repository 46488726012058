const styles = theme => ({
    container: {
        margin: 'auto',
        display: 'block',
    },
    loginForm: {
        margin: 'auto',
        width: 250,
        display: 'block',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 250,
    },
    textFieldFull: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "90%",
    },
    dense: {
        marginTop: 19,
    },
    menu: {
        width: 200,
    },
    centerButon: {
          display: 'block',
          margin: 'auto',
    },
    buttonInBlock: {
      margin: theme.spacing.unit,
    }
});


export default styles
