/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:8dd6d16c-3a8c-4e6b-b40e-601a7ca8276b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_h5bhVHpby",
    "aws_user_pools_web_client_id": "3h2l6cnbdhtslcosfebetrbeiq",
    "oauth": {},
    "aws_content_delivery_bucket": "kanban-web-hosting-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d3nafc9r4pobnj.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://vtof344nzzbtvntbxtogfyvuay.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "kanbanwebrest",
            "endpoint": "https://uoxnyyl1ti.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
