import {connect} from 'react-redux'
import HomePage from "../components/HomePage";
import {clear,send,startOrder, signOut, getUser}  from '../actions/actions'


const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        onClear: () => {
            dispatch(clear());
        },
        onSend: (order,token,email,company_email=null, clientName, ckiEmail) => {
          dispatch(send({email: email,name:"ЦКИ",content:order,companyEmail: company_email, clientName: clientName, ckiEmail: ckiEmail }, token));
        },
        onNewOrder: () => {
          dispatch(startOrder());
        },
        onSignOut: () =>{
            dispatch(signOut());
        },
        onGetUser: () => {
            dispatch(getUser());
        },

    }
}


const  mapStateToProps = (state,ownProps) => {
    return {
        order: state.order,
        auth: state.auth,
        system: state.system
    }
}

const homeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage)


export default  homeContainer;
