import * as actionTypes from "./actionTypes"
import * as notifierActions from "./notifierActions"
import { Auth } from 'aws-amplify';
import  {API} from 'aws-amplify';


export function addItem(code,message) {
    return {
        type: actionTypes.ADD_ITEM,
        code: code,
        message,
        success: true
    }
}
export function clear() {
    return {
        type: actionTypes.CLEAR_ALL,

    }
}

export function getUser(){
  return function(dispatch) {
    Auth.currentAuthenticatedUser()
      .then(user => {
        dispatch({type: actionTypes.USER_REFRESH,user})
      })
      .catch(err => console.log(err));
    }
}

export function send(body,token) {
  return function(dispatch) {
         dispatch(startLoading())
         let apiName = 'kanbanwebrest';
          let path = '/orders';

          let myInit = {
            headers: {
              'Content-Type': 'application/json'
            }, body
          }

       API.post(apiName,path, myInit).then((response)=>{
           if (response.MessageId !== undefined || response.messageId !== undefined ){
            dispatch(notifierActions.enqueueSnackbar({
                message: 'Заказ отправлен.',
                options: {
                    variant: 'success',
                }
              })
            )
            dispatch(sendSuccess())
            dispatch(endLoading())
          }
          else {
            dispatch(notifierActions.enqueueSnackbar({
                message: 'Ошибка.',
                options: {
                    variant: 'error',
                }
              })
              
          )
          dispatch(endLoading())
        }
      }).catch(error => {
        dispatch(notifierActions.enqueueSnackbar({
            message: 'Ошибка.' +error,
            options: {
                variant: 'error',
            }
          })
        )
        dispatch(endLoading())
      })

  }
}

export function signOut(){

  return function(dispatch){
    Auth.signOut()
    .then(data => {
      dispatch({type: actionTypes.SIGN_OUT})
    })
    .catch(err => {
      console.log(err)
      dispatch(notifierActions.enqueueSnackbar({
            message: 'Ошибка.' +err,
            options: {
                variant: 'error',
            }
      }))        
    })
  }
}

export function sendSuccess() {
    return {
        type: actionTypes.SEND_ORDER_SUCCESS,
    }
}
export function  startOrder(){
  return {
      type: actionTypes.START_ORDER,

  }
}

export function  cancelOrder(){
  return {
      type: actionTypes.CANCEL_ORDER,
  }
}
export function startLoading() {
  return {
    type: actionTypes.START_LOADING,
    text: 'загрузка'
  }
}

export function endLoading() {
  return {
    type: actionTypes.END_LOADING
  }
}
