import React, { Component } from "react";
import QrReader from "react-qr-reader";
import styles from '../styles'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import RequestContainer from '../containers/RequestContainer'
import Typography from '@material-ui/core/Typography';

class Reader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delay: 300,
            result: "",
            resolution: 1920,
            hasLink: false,
            debug: false
        };
        this.handleScan = this.handleScan.bind(this);
    }
    handleScan(data) {

        if (data) {
            this.setState({
              result: data, hasLink: true})
        };

    }


    handleError(err) {
        console.error(err);
    }

    onClick = () => {
        console.log(this.props)
    }


     simulate = () => {
          this.setState({result: 'Тестовая позиция для проверки отправки/78', hasLink: true})
     }

     newItem = () => {
         this.setState({result: '', hasLink: false})
         this.props.onNew()
     }

     sendOrder = () => {
       this.setState({result: '', hasLink: false})
       this.props.onSend()
     }

     increaseResolution = () => {
         this.setState({resolution: this.state.resolution+100})
         console.log(this.state.resolution)
     }

    render() {
        const {classes} = this.props;

        return (
            <div>
                {this.state.hasLink &&
                    <div>
                        <RequestContainer placeid={this.state.result} onNew = {this.newItem} onSend = {this.sendOrder}/>
                    </div>
                }

                {!this.state.hasLink &&
                    <div>
                        <Typography variant="h6" align="center" color="textPrimary" gutterBottom>
                            Просканируйте QR код
                            
                        </Typography>
                        {/* <Button color="inherit" onClick = {this.increaseResolution}>Увеличить разрешение</Button> */}
                          {this.state.debug && <Button variant="outlined" color="primary" className={classes.button} onClick={this.simulate}>
                                                      simulate
                                                  </Button>
                          }
                        <QrReader
                            delay={this.state.delay}
                            onError={this.handleError}
                            resolution = {this.state.resolution}
                            onScan={this.handleScan}
                            style={{ width: "100%"}}
                        />
                    </div>}

            </div>
        );
    }
}

export default withStyles(styles)(Reader);
