import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import {store, history,persistor} from "./store/configureStore"
import {  Route, Switch } from "react-router-dom";
import {ConnectedRouter} from 'connected-react-router'
import indexRoutes from "./routes/index"
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';
import {MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
    palette: {
        primary: green, // Purple and green play nicely together.
        secondary: {
            ...orange,
            A400: '#00e677',
        },
        error: red,
    },

});

ReactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider>
                <PersistGate loading={null} persistor={persistor}>
                        <ConnectedRouter history={history}>
                            <Switch>
                                {indexRoutes.map((prop, key) => {
                                    return <Route path={prop.path} component={prop.component} key={key} />;
                                })}
                            </Switch>
                        </ConnectedRouter>
                </PersistGate>
            </SnackbarProvider>
        </MuiThemeProvider>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
