import React from "react";
import Button from '@material-ui/core/Button';
import styles from '../styles'
import { withStyles } from '@material-ui/core/styles';
import {  JS, ConsoleLogger as Logger } from '@aws-amplify/core';
import TextField from '@material-ui/core/TextField';
import {  SignIn} from 'aws-amplify-react';
import Auth from '@aws-amplify/auth';
import Progress from './Progress'
const logger = new Logger('SignIn');

class Login extends SignIn {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
    };
  }


  handleChange = name => event => {
      this.setState({
        [name]: event.target.value,
      });

    };

  handleSubmit = event => {
    event.preventDefault();
    this.signIn();

  }
  checkContact(user) {
      if (!Auth || typeof Auth.verifiedContact !== 'function') {
          throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
      }
      Auth.verifiedContact(user)
          .then(data => {
              if (!JS.isEmpty(data.verified)) {
                  this.changeState('signedIn', user);
                  this.props.loginSuccess(data,user);
              } else {
                  user = Object.assign(user, data);
                  this.changeState('verifyContact', user);
              }
          });
  }

  async signIn() {
      const { username, password } = this.state;
      if (!Auth || typeof Auth.signIn !== 'function') {
          throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
      }
      this.setState({loading: true});
      try {
          const user = await Auth.signIn(username, password);
          logger.debug(user);
          if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
              logger.debug('confirm user with ' + user.challengeName);
              this.changeState('confirmSignIn', user);
          } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              logger.debug('require new password', user.challengeParam);
              this.changeState('requireNewPassword', user);
          } else if (user.challengeName === 'MFA_SETUP') {
              logger.debug('TOTP setup', user.challengeParam);
              this.changeState('TOTPSetup', user);
          } else {
              this.checkContact(user);
          }
      } catch (err) {
          if (err.code === 'UserNotConfirmedException') {
              logger.debug('the user is not confirmed');
              this.changeState('confirmSignUp', {username});
          } else if (err.code === 'PasswordResetRequiredException') {
              logger.debug('the user requires a new password');
              this.changeState('forgotPassword', {username});
          } else {
              this.error(err);
          }
      } finally {
          this.setState({loading: false})
      }
  }


  render() {
    const {classes} = this.props
    const loadingText = "выполняется вход";
    if (this.state.loading) {
      return(
        <Progress waitingText={loadingText}/>
      )
    }
    else {
    return (
      <div className={classes.container}>
        <form onSubmit={this.handleSubmit}   className={classes.loginForm}>
          <TextField
            autoFocus = {true}
            name="username"
            id="email"
            label="email"
            type ="string"
            autoComplete="email"
            onChange={this.handleChange('username')}
            className={classes.textField}
            margin="normal"
            />
            <br/>
          <TextField
              name="password"
              id="password"
              label="пароль"
              type ="password"
              autoComplete="password"
              onChange={this.handleChange('password')}
              className={classes.textField}
              margin="normal"
              />
              <br/>
              <div>
          <Button
            type="submit"
            className ={classes.centerButon}
          >
            Войти
          </Button>
          </div>
        </form>
      </div>
    );
  }
  }
}
export default withStyles(styles)(Login);
