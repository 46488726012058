import {connect} from 'react-redux'
import SubmitRequestForm from "../components/SubmitRequestForm";
import {addItem}  from '../actions/actions'


const mapDispatchToProps = (dispatch) => {
    return {
        onAddItem: (item,message) => {
            dispatch(addItem(item,message))
        }
    }
}


const  mapStateToProps = (state, ownProps) => {
    return {
        order: state.order,
        placeid: ownProps.placeid,
        onNew: ownProps.onNew,
        onSend: ownProps.onSend  ,
    }
}

export default  connect(
    mapStateToProps,
    mapDispatchToProps
)(SubmitRequestForm);
