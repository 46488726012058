import React, { Component } from 'react';

import './App.css';
import { withRouter, Route,Switch } from "react-router-dom";
import HomeContainer from "./containers/HomeContainer";

import { createMuiTheme } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { withStyles } from '@material-ui/core/styles';
import Notifier from './components/Notifier'
import LoginContainer from './containers/LoginContainer'

import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';
import { ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword,  SignUp, VerifyContact, withAuthenticator } from 'aws-amplify-react';
Amplify.configure(awsmobile);



createMuiTheme({
    palette: {
        primary: green, // Purple and green play nicely together.
        secondary: {
            ...orange,
            A400: '#00e677',
        },
        error: red,
    },

});
const styles = theme =>({
  flexLine: {
      flexGrow: 1,
      height: 1,
      marginLeft: "10px",
      marginRight: "10px",
  },
})



class App extends Component {


    render() {
  //     if (this.props.authState == "signedIn") {
          return (
            <React.Fragment>
                <Notifier />
                 <div className='App'>
                   <Switch>
                      <Route exact path="/" component={HomeContainer}/>
                      <Route component={NoMatch} />
                  </Switch>

                </div>
            </React.Fragment>
          );
    //    } else {
      //    return null
      //  }

  }
}

//export default withRouter(withStyles(styles)(App))
//export default withRouter(withStyles(styles)(withAuthenticator(App, false)))

export default withRouter(withStyles(styles)(withAuthenticator(App, false, [
  <LoginContainer/>,
  <ConfirmSignIn/>,
  <VerifyContact/>,
  <SignUp/>,
  <ConfirmSignUp/>,
  <ForgotPassword/>,
  <RequireNewPassword />
])))


function NoMatch({ location }) {
  return (
    <div>
      <h3>
        Упс. Не найден путь <code>{location.pathname}</code>
      </h3>
    </div>
  );
}
