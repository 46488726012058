import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';


const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  version: {
    marginLeft: 10,
    marginTop: 4,
  }
};

function ButtonAppBar(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Toolbar >
          <Typography variant="h6" color="primary" >
            ЦКИ
          </Typography>
          <Typography variant="caption" color="primary" className={classes.version} >
             v1.4.3
          </Typography>
          <div className={classes.grow} />
          <div>

          <Typography variant="caption" gutterBottom>
             {props.name}
           </Typography>
           <Button color="inherit" onClick = {props.signOut}>выйти</Button>
          </div>
        </Toolbar>

      </AppBar>
    </div>
  );
}

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ButtonAppBar);
