import { Auth } from "aws-amplify";
import * as actionTypes from "./actionTypes"
import { ConsoleLogger as Logger } from '@aws-amplify/core';

const logger = new Logger('SignIn');

export function login(username, password) {
  return function(dispatch) {
      return new Promise((resolve, reject) =>{
        try {
           const user =  Auth.signIn(username, password);
           logger.debug(user);
           resolve(user)
       } catch (err) {
           if (err.code === 'UserNotConfirmedException') {
               logger.debug('the user is not confirmed');
           } else if (err.code === 'PasswordResetRequiredException') {
               logger.debug('the user requires a new password');
           } else {
               this.error(err);
           }
           reject();
       }
      })
    }
}


export function loginSuccess(data, user) {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    data,
    user
  }

}
