import * as actionTypes from "../actions/actionTypes";
export default function order(state={codes:[], pages: []}, action) {
    switch (action.type) {

        case actionTypes.ADD_ITEM:
            return {
                ...state,
                codes: [...state.codes, {code: action.code,message: action.message}],
            }


        case actionTypes.CLEAR_ALL:
            return {
                active_order: false,
                codes:[]
            }

        case actionTypes.START_ORDER:
            return {
                ...state,
                active_order: true,
            }


        case  actionTypes.SEND_ORDER_SUCCESS:
            return {
                codes:[]
            }
        case actionTypes.SIGN_OUT:
                return {
                    codes:[],
                    pages: []
                }
    

        default:
            return state;
    }
}
