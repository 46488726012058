import { combineReducers } from 'redux'


import order  from './order'
import system from './system'
import notifier from './notifier'
import auth from './auth'

import { connectRouter } from 'connected-react-router'

const rootReducer  = (history) => combineReducers({
    router: connectRouter(history),
    order,
    system,
    notifier,
    auth
})

export default rootReducer
