import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    margin: 'auto'
  },
  orderDescirption: {
    padding: 10
  }

});


function BuyerCart(props) {
  const { classes, items,auth } = props;

  return (
    <div className={classes.orderDescirption}  >
     <Typography variant="body1" gutterBottom>  {auth.email} </Typography>
     <Typography variant="body1" gutterBottom>  {auth.attributes["custom:company_email"]}</Typography>
        <br/>
        В корзине {items.length} позиций.
    </div>
  )
  // return (
  //   <div className={classes.root}>
  //     <List component="nav" className={classes.list}>
  //         {items.map((item) => {
  //           return(
  //           <ListItem>
  //                     <ListItemText
  //                       primary={item}
  //                       secondary={null}
  //                     />
  //                     <ListItemSecondaryAction>
  //                       <IconButton aria-label="Delete">
  //                         <DeleteIcon />
  //                       </IconButton>
  //                     </ListItemSecondaryAction>
  //                   </ListItem>
  //                 )
  //         })}
  //     </List>
  //   </div>
  // );
}

BuyerCart.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array
};

export default withStyles(styles)(BuyerCart);
