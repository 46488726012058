import React from 'react';
import Typography from '@material-ui/core/Typography';
import BuyerCart from './BuyerCart'
import styles from '../styles'
import { withStyles } from '@material-ui/core/styles';
import Reader from './Reader'
import Button from '@material-ui/core/Button';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Header from "./Header"
import Progress from './Progress'


class HomePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {scanMode: false, showCart: false};
    }

    componentDidMount(){
       this.props.onGetUser() 
    }

  onNew =() =>{
    this.props.onNewOrder();
    this.setState({scanMode: true})
  }

  onClear =() =>{
    this.props.onClear();
    this.setState({scanMode: true})
  }
  onSend =() =>{
    this.props.onSend(this.props.order,this.props.auth.token, this.props.auth.email,
      this.props.auth.attributes["custom:company_email"],
      this.props.auth.attributes["custom:client_name"],
      this.props.auth.attributes["custom:cki_email"]);
    this.setState({scanMode: false})
  }
  exit = () => {
    this.setState({scanMode: false})
  }

  toggleDrawer = (open) => () => {
   this.setState({
     showCart: open,
   });
 };

   signOut = () => {
    this.props.onSignOut()
   }


  render() {
    const {order,classes,auth,system} = this.props;
    let countItems = order.codes.length
    return (
      <div>
      <Header signOut = {this.signOut.bind(this)} name = {auth.email}/>
          <br/>
          {(this.state.scanMode && !system.isWaiting && order.active_order) && <Reader onNew={this.onNew.bind(this)} onSend={this.onSend.bind(this)} active_order={order.active_order}/>}
          {(!this.state.scanMode || !order.active_order )&& !system.isWaiting &&
            <div>
            <Button variant="contained" color="primary" className={classes.buttonInBlock} onClick={this.onNew}>
              {countItems > 0 && order.active_order && "Продложить заказ"}
              {(countItems === 0 || !order.active_order) && "Начать новый заказ"}
            </Button>
            {countItems > 0  && order.active_order &&  <Button variant="contained" color="primary" className={classes.buttonInBlock} onClick={this.onSend}>
              Отправить
            </Button>
            }
            </div>
          }
          {system.isWaiting && <Progress waitingText={system.waitingText}/>}
          <br/>
          {countItems > 0  &&  (<Typography component="div">
                                      Количество позиций в корзине: {countItems}.
                                  </Typography>  )}

          {countItems > 0  &&

                 <div>
                  <Button variant="outlined" color="primary" onClick={this.toggleDrawer(true)}>Показать текущий заказ</Button>
                 </div>
               }

          <SwipeableDrawer
           anchor="top"
           open={this.state.showCart}
           onClose={this.toggleDrawer(false)}
           onOpen={this.toggleDrawer(true)}
         >
           <div
             tabIndex={0}
             role="button"
             onClick={this.toggleDrawer( false)}
             onKeyDown={this.toggleDrawer(false)}
           >
               <BuyerCart items={order.codes} auth = {auth}/>
               <Button variant="outlined" color="secondary" className={classes.button} onClick={this.onClear}>
                   Очистить
               </Button>
           </div>
         </SwipeableDrawer>


      </div>
    )
  }
}

export default withStyles(styles)(HomePage);
