import {connect} from 'react-redux'
import Login from "../components/Login";
import {loginSuccess,login}  from '../actions/authAction'


const mapDispatchToProps = (dispatch) => {
    return {
        login: (email,password) => {
            dispatch(login(email,password))
        },
        loginSuccess: (data,user) => {
          dispatch(loginSuccess(data,user))
        }
    }
}


const  mapStateToProps = (state,ownProps) => {
    return {

    }
}

const loginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)


export default  loginContainer;
