import * as actionTypes from "../actions/actionTypes";

export default function auth(state={user:{attributes:{}}}, action) {
    switch (action.type) {

        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                user: action.user,
                email: action.data.verified.email,
                attributes: action.user.attributes
            }
        case actionTypes.USER_REFRESH:
                return {
                    ...state,
                }

        case actionTypes.SIGN_OUT:
            return {
                user:{attributes:{}}
            }
    
        default:
            return state;
    }
}
