import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import styles from '../styles'



class SubmitRequestForm extends  Component {
    constructor(props) {
        super(props);
        this.state = { placeid: "",  message: "", success: false };
    }


    handleSubmit =(event) => {
        this.props.onAddItem(this.state.placeid, this.state.message);
        this.setState({success: true})
        event.preventDefault();
    };

    componentDidMount =() =>{
       this.setState({placeid: this.props.placeid})
    }

    handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
      };

    newClicked = () =>
    {
        this.setState({success: false})
        this.props.onNew()
    }

    sendClicked = () =>
    {
        this.setState({success: false})
        this.props.onSend()
    }

    split = (input,i) => {
      let items = input.split('/')
      if (items[0]==="Yandex" && items.length == 4){
        if (i===0) return items[3]
        if (i===1) return "Артикул: " + items[1]
      }
      if (items.length > i){
        return items[i]
      }
      else{
        return "";
      }
    }


    render() {
        const {success } = this.state;
        const {classes} = this.props
        if (success) {
          return (
              <Typography variant="h6" align="center" color="textPrimary" gutterBottom>
                  Позиция добавлена в корзину
                  <br/>
                    <div>
                      <Button variant="outlined" color="primary" className={classes.buttonInBlock} onClick={this.newClicked}>
                         Продолжить
                      </Button>
                      <Button variant="outlined" color="primary" className={classes.buttonInBlock} onClick={this.sendClicked}>
                         Отправить
                      </Button>
                  </div>
               </Typography>
          )
        }
        return (
            <form className={classes.container}  onSubmit={this.handleSubmit}  noValidate autoComplete="off">
              <div>
                  <TextField
                    disabled
                    name="placeid"
                    id="placeid"
                    label="информация для заказа: "
                    defaultValue={this.split(this.props.placeid,0)}
                    //onChange={this.handleChange('placeid')}
                    className={classes.textFieldFull}
                      //multiline
                    />
             </div>
             <div>
                 <TextField
                   disabled
                   name="goodname"
                   id="goodname"
                   //label="информация для заказа: "
                   defaultValue={this.split(this.props.placeid,1)}
                  // onChange={this.handleChange('placeid')}
                   className={classes.textFieldFull}
                   multiline
                   />
            </div>
            <div>
            <TextField
                   name="message"
                   id="message"
                   placeholder="комментарий"
                   onChange={this.handleChange('message')}
                   className={classes.textFieldFull}
                   multiline
                   />
            </div>            
                <div>
                     <Button variant="outlined" color="primary" className={classes.buttonInBlock} type="submit">
                      Применить
                    </Button>
                    <Button variant="outlined" color="secondary" className={classes.buttonInBlock} onClick={this.newClicked}>
                       Отмена
                    </Button>
                </div>
            </form>
        );
    }
}


export default withStyles(styles)(SubmitRequestForm);
