export const ADD_ITEM ="ADD_ITEM"
export const CLEAR_ALL ="CLEAR_ALL"
export const SEND_ORDER_SUCCESS = "SEND_ORDER_SUCCESS"
export const SEND_ORDER_FAILED = "SEND_ORDER_FAILED"


export const START_LOADING="START_LOADING"
export const END_LOADING="END_LOADING"

export const START_ORDER="START_ORDER"
export const CANCEL_ORDER="CANCEL_ORDER"

export const LOGIN_SUCCESS="LOGIN_SUCCESS"
export const LOGIN_FAILED ="LOGIN_FAILED"

export const SIGN_OUT="SIGN_OUT"


export const USER_REFRESH="USER_REFRESH"
